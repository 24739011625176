export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'liveDate',
    label: 'field.resultDate',
  },
  {
    key: 'channelId',
    label: 'field.channelId',
  },
  {
    key: 'no',
    label: 'field.live',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'countMatches',
    label: 'field.numberOfMatch',
  },
  {
    key: 'genStatus',
    label: 'button.generate',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
