<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      v-if="searchFields.length"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col md="auto">
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', 'baccarat-result')">
            <b-button
              variant="primary"
              class="mr-1"
              @click="showGenerateByDayModal"
            >
              {{ $t("button.generateResult") }}
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="showCloneByDayModal"
            >
              {{ $t("button.clone") }}
            </b-button>
            <b-button
              variant="primary"
              :to="{ name: 'generate-baccarat-result' }"
              class="mr-1"
            >
              {{ $t("button.generate") }}
            </b-button>
            <b-button
              variant="primary"
              :to="{ name: 'create-baccarat-result' }"
            >
              {{ $t("button.create") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(channelId)="data">
            <span v-if="data.item.channelType == 2">
              BC {{ data.item.channelId - 2 }}
            </span>
            <span v-else> {{ data.item.channelId }} </span>
          </template>
          <template #cell(liveDate)="data">
            {{ data.item.liveDate | formatDate("DD-MM-YYYY") }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(status)="data">
            {{ $t(`baccaratResultStatus.${data.item.status}`) }}
          </template>
          <template #cell(genStatus)="data">
            {{ $t(`baccaratGenerateStatus.${data.item.genStatus}`) }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', 'baccarat-result')"
                v-b-tooltip.hover
                :title="$t('button.generate')"
                variant="primary"
                pill
                size="sm"
                @click="generateResult(data.item)"
              >
                <feather-icon icon="RefreshCcwIcon" />
              </b-button>
              <b-button
                v-if="$can('update', 'baccarat-result')"
                v-b-tooltip.hover
                :title="$t('button.generate')"
                variant="primary"
                pill
                size="sm"
                @click="generateMatchWithResult(data.item)"
              >
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
              <b-button
                v-if="$can('update', 'baccarat-result')"
                :to="{
                  name: 'update-baccarat-result',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="$can('create', 'baccarat-result')"
                :to="{
                  name: 'create-baccarat-result',
                  query: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.clone')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.view')"
                variant="primary"
                pill
                size="sm"
                @click="viewResult(data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="$can('delete', 'baccarat-result')"
                v-b-tooltip.hover
                :title="$t('button.delete')"
                :disabled="data.item.status != 1"
                variant="danger"
                pill
                size="sm"
                @click="destroy(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>

    <result-history-modal ref="resultHistoryModal" />
    <clone-by-day-modal ref="cloneByDayModal" @save="onSavedClone" />
    <generate-by-day-modal ref="genearteByDayModal" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import moment from "moment";
import ResultHistoryModal from "./resultHistory/List.vue";
import CloneByDayModal from "./cloneByDayModal/Index.vue";
import GenerateByDayModal from "./generateByDayModal/Index.vue";

const BaccaratResultRepository = Repository.get("baccaratResult");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    ResultHistoryModal,
    CloneByDayModal,
    GenerateByDayModal,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return !!this.$route.query.name;
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }

      return this.userData?.id;
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        liveDate: this.$route.query.liveDate || moment().format("YYYY-MM-DD"),
      },
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    showCloneByDayModal() {
      this.$refs.cloneByDayModal.show();
    },
    onSavedClone(date) {
      this.params.liveDate = date;
      this.list();
    },
    showGenerateByDayModal() {
      this.$refs.genearteByDayModal.show();
    },
    generateResult(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.generateResult"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.generate"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            BaccaratResultRepository.generateMatchResult({
              id: item.id,
            })
              .then((response) => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
    generateMatchWithResult(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.generateMatchAndResult"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.generate"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            BaccaratResultRepository.generateMatchAndResult({
              id: item.id,
            })
              .then((response) => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
    },
    viewResult(item) {
      this.$refs.resultHistoryModal.show(item.id);
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      BaccaratResultRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.delete"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            BaccaratResultRepository.destroy(item.id)
              .then((response) => {
                this.getData();
              })
              .catch((error) => {});
          }
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
