<template>
  <div>
    <b-modal hide-footer centered size="xl" v-model="isShow">
      <div>
        <b-row v-if="item.id" class="mb-1">
          <b-col md="6">
            {{ $t("field.resultDate") }}:
            {{ item.liveDate | formatDate("DD-MM-YYYY") }}
          </b-col>
          <b-col md="6">
            {{ $t("field.channel") }}:
            <span v-if="item.channelType == 2">
              BC {{ item.channelId - 2 }}
            </span>
            <span v-else> {{ item.channelId }} </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.live") }}:
            {{ item.no }}
          </b-col>
          <b-col md="6">
            {{ $t("field.status") }}:
            {{ $t(`baccaratResultStatus.${item.status}`) }}
          </b-col>
        </b-row>

        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="1"
          :per-page="total"
          :total="total"
          class="baccarate-result-table"
        >
          <template #cell(result)="data">
            <span :class="`text-${data.item.result}`">
              {{ $t(`baccarat.${data.item.result}`) }}
            </span>
          </template>
          <template #cell(totalCard)="data">
            {{ countTotalCardByMatch(data.item) }}
          </template>
          <template #cell(fightResult)="data">
            <span
              :class="`text-${data.item.fightResult}`"
              v-if="data.item.fightResult"
            >
              {{ $t(`baccarat.${data.item.fightResult}`) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(resultChange)="data">
            <span v-if="data.item.status">
              <b-badge
                pill
                variant="light-danger"
                v-if="data.item.resultChange == 1"
              >
                {{ $t("field.change") }}
              </b-badge>
              <b-badge pill variant="light-success" v-else>
                {{ $t("field.notChange") }}
              </b-badge>
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(resultChangeCorrect)="data">
            <span
              v-if="
                data.item.status &&
                data.item.resultChange == 1 &&
                data.item.fightResult
              "
            >
              <b-badge
                pill
                variant="light-success"
                v-if="
                  data.item.resultChangeTo != data.item.fightResult ||
                  (data.item.result == 3 &&
                    data.item.resultChangeTo == data.item.subResult)
                "
              >
                {{ $t("field.right") }}
              </b-badge>
              <b-badge pill variant="light-danger" v-else>
                {{ $t("field.wrong") }}
              </b-badge>
            </span>
            <span v-else>-</span>
          </template>
          <template #cell(player)="data">
            <div class="baccarat-result-player">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.baccaPlayerCardOne
                    ? data.item.baccaPlayerCardOne
                    : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.baccaPlayerCardTwo
                    ? data.item.baccaPlayerCardTwo
                    : 'back'
                }.png`"
              />
              <div>
                <b-img
                  class="result result-3"
                  :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.item.baccaPlayerCardThree}.png`"
                  v-if="data.item.baccaPlayerCardThree"
                />
              </div>
            </div>
          </template>
          <template #cell(banker)="data">
            <div class="baccarat-result-banker">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.baccaBankerCardOne
                    ? data.item.baccaBankerCardOne
                    : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.baccaBankerCardTwo
                    ? data.item.baccaBankerCardTwo
                    : 'back'
                }.png`"
              />
              <div>
                <b-img
                  class="result result-3"
                  :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.item.baccaBankerCardThree}.png`"
                  v-if="data.item.baccaBankerCardThree"
                />
              </div>
            </div>
          </template>
          <template #cell(resultPlayer)="data">
            <div class="baccarat-result-player">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.resultBaccaPlayerCardOne
                    ? data.item.resultBaccaPlayerCardOne
                    : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.resultBaccaPlayerCardTwo
                    ? data.item.resultBaccaPlayerCardTwo
                    : 'back'
                }.png`"
              />
              <div>
                <b-img
                  class="result result-3"
                  :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.item.resultBaccaPlayerCardThree}.png`"
                  v-if="data.item.resultBaccaPlayerCardThree"
                />
              </div>
            </div>
          </template>
          <template #cell(resultBanker)="data">
            <div class="baccarat-result-banker">
              <b-img
                class="result result-1"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.resultBaccaBankerCardOne
                    ? data.item.resultBaccaBankerCardOne
                    : 'back'
                }.png`"
              />
              <b-img
                class="result result-2"
                :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${
                  data.item.resultBaccaBankerCardTwo
                    ? data.item.resultBaccaBankerCardTwo
                    : 'back'
                }.png`"
              />
              <div>
                <b-img
                  class="result result-3"
                  :src="`https://imagesassets.s3.ap-southeast-1.amazonaws.com/baccarat/vertical-card/${data.item.resultBaccaBankerCardThree}.png`"
                  v-if="data.item.resultBaccaBankerCardThree"
                />
              </div>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-button
              v-b-tooltip.hover
              @click="viewBetHistory(data.item)"
              :title="$t('button.view')"
              variant="primary"
              pill
              size="sm"
              v-if="$can('read', 'live-bet-history')"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <span v-else></span>
          </template>

          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th></b-th>
              <b-th>
                <h5 class="font-weight-bold mb-0">
                  <span class="text-wala">{{ item.countPlayer }}</span> |
                  <span class="text-meron">{{ item.countBanker }}</span>
                  <span class="d-block text-draw">{{ item.countTie }}</span>
                </h5>
              </b-th>
              <b-th>
                <h5 class="font-weight-bold mb-0">
                  {{ countTotalCard() }}
                </h5>
              </b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th v-if="$can('read', 'live-bet-history')"></b-th>
            </b-tr>
          </template>
        </n-table>
      </div>
    </b-modal>

    <bet-history ref="betHistory" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BBadge,
  BImg,
  BTr,
  BTh,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import noActionTableFields from "./noActionTableField";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import BetHistory from "../betHistory/List.vue";

const BaccaratResultRepository = Repository.get("baccaratResult");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BBadge,
    BImg,
    BTr,
    BTh,
    BButton,

    NTable,
    BetHistory,
  },
  watch: {
    perPage(value) {
      this.list();
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    fields() {
      if (!this.$can("read", "live-bet-history")) {
        return noActionTableFields;
      }

      return tableFields;
    },
  },

  data() {
    return {
      isShow: false,
      loading: false,

      item: {},
      items: [],
      total: 0,
    };
  },
  methods: {
    countTotalCard() {
      let a = 0;
      let b = 0;
      let c = 0;

      let i = 0;
      this.items.forEach((element) => {
        let count = this.countTotalCardByMatch(element);
        if (count == 4) {
          a++;
        }
        if (count == 5) {
          b++;
        }
        if (count == 6) {
          c++;
        }
      });

      return `4=${a} | 5=${b} | 6=${c}`;
    },
    countTotalCardByMatch(item) {
      let i = 0;
      if (item.baccaPlayerCardOne) {
        i++;
      }
      if (item.baccaPlayerCardTwo) {
        i++;
      }
      if (item.baccaPlayerCardThree) {
        i++;
      }
      if (item.baccaBankerCardOne) {
        i++;
      }
      if (item.baccaBankerCardTwo) {
        i++;
      }
      if (item.baccaBankerCardThree) {
        i++;
      }

      return i;
    },
    viewBetHistory(item) {
      this.$refs.betHistory.show(item, this.item);
    },
    show(id) {
      this.isShow = true;
      this.item = {};
      this.items = [];
      this.total = 0;
      this.getData(id);
    },
    hide() {
      this.isShow = false;
    },
    getData(id) {
      this.loading = true;
      BaccaratResultRepository.show(id)
        .then((response) => {
          if (response?.data?.data) {
            const data = response.data.data;
            this.item = { ...data };
            this.items = [...data.fightMatch];
            this.total = data.fightMatch.length;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
