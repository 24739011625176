<template>
  <div>
    <b-modal hide-footer centered size="xl" v-model="isShow">
      <div>
        <b-row v-if="result.id" class="mb-1">
          <b-col md="6">
            {{ $t("field.resultDate") }}:
            {{ result.liveDate | formatDate("DD-MM-YYYY") }}
          </b-col>
          <b-col md="6">
            {{ $t("field.channel") }}:
            <span v-if="result.channelType == 2">
              BC {{ result.channelId - 2 }}
            </span>
            <span v-else> {{ result.channelId }} </span>
          </b-col>
          <b-col md="6">
            {{ $t("field.live") }}:
            {{ result.no }}
          </b-col>
          <b-col md="6">
            {{ $t("field.match") }}:
            {{ item.no }}
          </b-col>
          <b-col md="6">
            {{ $t("field.status") }}:
            {{ $t(`baccaratResultStatus.${item.status}`) }}
          </b-col>
        </b-row>

        <n-search-container
          :active="true"
          :show-header="false"
          @search="search"
          show-search
          @reset="reset"
          :loading="loading"
        >
          <n-search-input ref="search" :fields="searchFields" v-model="params">
          </n-search-input>
        </n-search-container>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="1"
          :per-page="total"
          :total="total"
          class="baccarate-result-table"
        >
          <template #cell(selected)="data">
            <span :class="`text-${data.item.selected}`">
              {{ $t(`baccarat.${data.item.selected}`) }}
            </span>
          </template>
          <template #cell(amount)="data">
            {{ data.item.amount | currency(data.item.ccy) }}
          </template>
          <template #cell(incrAmount)="data">
            {{ data.item.incrAmount | currency(data.item.ccy) }}
          </template>

          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th></b-th>
              <b-th></b-th>
              <b-th></b-th>
              <b-th>
                <h5 v-if="totalReport.outstanding" class="font-weight-bold">
                  <span class="text-meron">
                    {{
                      totalReport.outstanding.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstanding.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br />
                  <span class="text-draw">
                    {{
                      totalReport.outstanding.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th>
                <h5 v-if="totalReport.outstandingIncr" class="font-weight-bold">
                  <span class="text-meron">
                    {{
                      totalReport.outstandingIncr.meronBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.outstandingIncr.walaBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br />
                  <span class="text-draw">
                    {{
                      totalReport.outstandingIncr.drawBetTotal
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BBadge,
  BImg,
  BTr,
  BTh,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NPagination from "@/components/NPagination";

const BetTrasactionRepository = Repository.get("betTransaction");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BBadge,
    BImg,
    BTr,
    BTh,

    NTable,
    NSearchContainer,
    NSearchInput,
    NPagination,
  },
  watch: {
    perPage(value) {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      isShow: false,
      loading: false,

      result: {},
      item: {},
      items: [],
      total: 0,
      totalReport: {},

      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || "",
      },

      searchFields: searchInputs,
    };
  },
  methods: {
    show(item, result) {
      this.isShow = true;
      this.item = {
        ...item,
      };
      this.result = {
        ...result,
      };
      this.items = [];
      this.total = 0;
      this.getData(item.fightMatchId);
    },
    hide() {
      this.isShow = false;
    },
    list() {
      this.getData(this.item.fightMatchId);
    },
    search(searchText) {
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = searchText;
      this.getData(this.item.fightMatchId);
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.search = "";
      this.getData(this.item.fightMatchId);
    },
    getData(id) {
      this.loading = true;
      BetTrasactionRepository.index({
        ...this.params,
        fightMatchId: id,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
            this.getTotal(id);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal(id) {
      this.loading = true;
      BetTrasactionRepository.total({
        ...this.params,
        fightMatchId: id,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.totalReport = { ...data };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];
    return {
      fields,
    };
  },
};
</script>
