export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'selected',
    label: 'field.bet',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'incrAmount',
    label: 'field.increaseAmount',
  },
];
