export default [
  {
    key: 'no',
    label: 'field.match',
    thClass: 'w-50px',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'result',
    label: 'field.preResult',
  },
  {
    key: 'totalCard',
    label: 'field.totalCard',
  },
  {
    key: 'resultChange',
    label: 'field.needToChange',
  },
  {
    key: 'fightResult',
    label: 'field.result',
  },
  {
    key: 'resultChangeCorrect',
    label: 'field.changeCorrect',
  },
  {
    key: 'banker',
    label: 'field.banker',
  },
  {
    key: 'player',
    label: 'field.player',
  },
  {
    key: 'resultBanker',
    label: 'field.resultBanker',
  },
  {
    key: 'resultPlayer',
    label: 'field.resultPlayer',
  },
];
