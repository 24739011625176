<template>
  <b-modal
    hide-footer
    centered
    no-close-on-backdrop
    size="md"
    v-model="isShow"
    :title="$t('button.generateResult')"
  >
    <validation-observer ref="form" #default="{ invalid }">
      <n-form-confirmation
        ref="confirmForm"
        key="form"
        @submit="save"
        :form="$refs.form"
        :disabled="invalid"
      >
        <n-input :key="`input-${key}`" :fields="fields" v-model="data">
        </n-input>
        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="button"
              variant="primary"
              :loading="loading"
              @submit="submit"
            >
              {{ $t("button.save") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading.vue";
import FormInput from "./formInput";
import Repository from "@/repositories/RepositoryFactory";

const BaccaratResultRepository = Repository.get("baccaratResult");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    NInput,
    NFormConfirmation,
    NButtonLoading,
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    show() {
      this.data = {
        date: "",
      };
      this.isShow = true;
      this.key++;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      BaccaratResultRepository.generateByDay(this.data)
        .then((response) => {
          this.$emit("save");
          this.hide();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.form.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;

    return { fields };
  },
};
</script>