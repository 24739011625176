var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"hide-footer":"","centered":"","no-close-on-backdrop":"","size":"xl","title":_vm.$t('button.clone')},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"form",ref:"confirmForm",attrs:{"form":_vm.$refs.form,"disabled":invalid},on:{"submit":_vm.save}},[(!_vm.result.bcone.length && !_vm.result.bctwo.length)?_c('n-input',{key:("input-" + _vm.key),attrs:{"fields":_vm.fields},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}):_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":"date","name":_vm.$t('field.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.date"))+" ")]),_c('n-date-picker',{ref:"date",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"name":"date"},model:{value:(_vm.result.date),callback:function ($$v) {_vm.$set(_vm.result, "date", $$v)},expression:"result.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"pr-lg-2",attrs:{"lg":"6"}},[_c('div',{staticClass:"form-block"},[_c('h4',{staticClass:"text-center"},[_vm._v("BC1")]),_vm._l((_vm.result.bcone),function(item,i){return _c('b-row',{key:("item-" + i)},[(i > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("field.live"))+": "+_vm._s(item.no))])]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("match-" + i),"name":_vm.$t('field.match'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.match"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.match'),"name":("match-" + i),"autocomplete":"off"},model:{value:(item.countMatches),callback:function ($$v) {_vm.$set(item, "countMatches", $$v)},expression:"item.countMatches"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("fourCard-" + i),"name":_vm.$t('field.fourCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.fourCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.fourCard'),"name":("fourCard-" + i),"autocomplete":"off"},model:{value:(item.fourCard),callback:function ($$v) {_vm.$set(item, "fourCard", $$v)},expression:"item.fourCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("fiveCard-" + i),"name":_vm.$t('field.fiveCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.fiveCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.fiveCard'),"name":("fiveCard-" + i),"autocomplete":"off"},model:{value:(item.fiveCard),callback:function ($$v) {_vm.$set(item, "fiveCard", $$v)},expression:"item.fiveCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("sixCard-" + i),"name":_vm.$t('field.sixCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.sixCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.sixCard'),"name":("sixCard-" + i),"autocomplete":"off"},model:{value:(item.sixCard),callback:function ($$v) {_vm.$set(item, "sixCard", $$v)},expression:"item.sixCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"danger","block":"","disabled":_vm.result.bcone.length <= 0},on:{"click":function($event){return _vm.removeMatch(1)}}},[_vm._v(" "+_vm._s(_vm.$t("button.removeMatch"))+" ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.addMatch(1)}}},[_vm._v(" "+_vm._s(_vm.$t("button.addMatch"))+" ")])],1)],1)],2)]),_c('b-col',{staticClass:"mt-3 mt-lg-0 pl-lg-2",attrs:{"lg":"6"}},[_c('div',{staticClass:"form-block"},[_c('h4',{staticClass:"text-center"},[_vm._v("BC2")]),_vm._l((_vm.result.bctwo),function(item,i){return _c('b-row',{key:("item-" + i)},[(i > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.$t("field.live"))+": "+_vm._s(item.no))])]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("match2-" + i),"name":_vm.$t('field.match'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.match"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.match'),"name":("match2-" + i),"autocomplete":"off"},model:{value:(item.countMatches),callback:function ($$v) {_vm.$set(item, "countMatches", $$v)},expression:"item.countMatches"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("fourCard2-" + i),"name":_vm.$t('field.fourCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.fourCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.fourCard'),"name":("fourCard2-" + i),"autocomplete":"off"},model:{value:(item.fourCard),callback:function ($$v) {_vm.$set(item, "fourCard", $$v)},expression:"item.fourCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("fiveCard2-" + i),"name":_vm.$t('field.fiveCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.fiveCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.fiveCard'),"name":("fiveCard2-" + i),"autocomplete":"off"},model:{value:(item.fiveCard),callback:function ($$v) {_vm.$set(item, "fiveCard", $$v)},expression:"item.fiveCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"fightResult"},[_c('validation-provider',{attrs:{"vid":("sixCard2-" + i),"name":_vm.$t('field.sixCard'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t("field.sixCard"))+" ")]),_c('div',[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.sixCard'),"name":("sixCard2-" + i),"autocomplete":"off"},model:{value:(item.sixCard),callback:function ($$v) {_vm.$set(item, "sixCard", $$v)},expression:"item.sixCard"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"danger","block":"","disabled":_vm.result.bctwo.length <= 0},on:{"click":function($event){return _vm.removeMatch(2)}}},[_vm._v(" "+_vm._s(_vm.$t("button.removeMatch"))+" ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.addMatch(2)}}},[_vm._v(" "+_vm._s(_vm.$t("button.addMatch"))+" ")])],1)],1)],2)])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('n-button-loading',{attrs:{"type":"button","variant":"primary","loading":_vm.loading,"disabled":!_vm.result.bcone.length && !_vm.result.bctwo.length},on:{"submit":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }