export default [
  {
    key: 'liveDate',
    label: 'field.resultDate',
    type: 'date',
    operator: 'd=',
    cols: 3,
    noMaxDate: true,
  },
];
