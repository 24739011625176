<template>
  <b-modal
    hide-footer
    centered
    no-close-on-backdrop
    size="xl"
    v-model="isShow"
    :title="$t('button.clone')"
  >
    <validation-observer ref="form" #default="{ invalid }">
      <n-form-confirmation
        ref="confirmForm"
        key="form"
        @submit="save"
        :form="$refs.form"
        :disabled="invalid"
      >
        <n-input
          :key="`input-${key}`"
          :fields="fields"
          v-model="data"
          v-if="!result.bcone.length && !result.bctwo.length"
        >
        </n-input>
        <b-row no-gutters v-else>
          <b-col cols="12">
            <b-form-group class="fightResult">
              <validation-provider
                #default="{ errors }"
                :vid="`date`"
                :name="$t('field.date')"
                rules="required"
              >
                <label>
                  {{ $t("field.date") }}
                </label>
                <n-date-picker
                  :ref="`date`"
                  v-model="result.date"
                  :name="`date`"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- BC1 -->
          <b-col lg="6" class="pr-lg-2">
            <div class="form-block">
              <h4 class="text-center">BC1</h4>
              <b-row v-for="(item, i) in result.bcone" :key="`item-${i}`">
                <b-col cols="12" v-if="i > 0">
                  <hr />
                </b-col>
                <b-col cols="12">
                  <h6>{{ $t("field.live") }}: {{ item.no }}</h6>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`match-${i}`"
                      :name="$t('field.match')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.match") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.countMatches"
                          :placeholder="$t('field.match')"
                          :name="`match-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`fourCard-${i}`"
                      :name="$t('field.fourCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.fourCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.fourCard"
                          :placeholder="$t('field.fourCard')"
                          :name="`fourCard-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`fiveCard-${i}`"
                      :name="$t('field.fiveCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.fiveCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.fiveCard"
                          :placeholder="$t('field.fiveCard')"
                          :name="`fiveCard-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`sixCard-${i}`"
                      :name="$t('field.sixCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.sixCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.sixCard"
                          :placeholder="$t('field.sixCard')"
                          :name="`sixCard-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-button
                    variant="danger"
                    block
                    @click="removeMatch(1)"
                    class="mt-1"
                    :disabled="result.bcone.length <= 0"
                  >
                    {{ $t("button.removeMatch") }}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    variant="primary"
                    block
                    @click="addMatch(1)"
                    class="mt-1"
                  >
                    {{ $t("button.addMatch") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <!-- BC2 -->
          <b-col lg="6" class="mt-3 mt-lg-0 pl-lg-2">
            <div class="form-block">
              <h4 class="text-center">BC2</h4>
              <b-row v-for="(item, i) in result.bctwo" :key="`item-${i}`">
                <b-col cols="12" v-if="i > 0">
                  <hr />
                </b-col>
                <b-col cols="12">
                  <h6>{{ $t("field.live") }}: {{ item.no }}</h6>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`match2-${i}`"
                      :name="$t('field.match')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.match") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.countMatches"
                          :placeholder="$t('field.match')"
                          :name="`match2-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`fourCard2-${i}`"
                      :name="$t('field.fourCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.fourCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.fourCard"
                          :placeholder="$t('field.fourCard')"
                          :name="`fourCard2-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`fiveCard2-${i}`"
                      :name="$t('field.fiveCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.fiveCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.fiveCard"
                          :placeholder="$t('field.fiveCard')"
                          :name="`fiveCard2-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="fightResult">
                    <validation-provider
                      #default="{ errors }"
                      :vid="`sixCard2-${i}`"
                      :name="$t('field.sixCard')"
                      rules="required|integer"
                    >
                      <label>
                        {{ $t("field.sixCard") }}
                      </label>
                      <div>
                        <b-form-input
                          v-model="item.sixCard"
                          :placeholder="$t('field.sixCard')"
                          :name="`sixCard2-${i}`"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                          autocomplete="off"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-button
                    variant="danger"
                    block
                    @click="removeMatch(2)"
                    class="mt-1"
                    :disabled="result.bctwo.length <= 0"
                  >
                    {{ $t("button.removeMatch") }}
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    variant="primary"
                    block
                    @click="addMatch(2)"
                    class="mt-1"
                  >
                    {{ $t("button.addMatch") }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="button"
              variant="primary"
              :loading="loading"
              :disabled="!result.bcone.length && !result.bctwo.length"
              @submit="submit"
            >
              {{ $t("button.save") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading.vue";
import FormInput from "./formInput";
import Repository from "@/repositories/RepositoryFactory";
import NDatePicker from "@/components/NDatePicker.vue";

const BaccaratResultRepository = Repository.get("baccaratResult");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    NInput,
    NFormConfirmation,
    NButtonLoading,
    NDatePicker,
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      data: {},
      result: {
        date: null,
        bcone: [],
        bctwo: [],
      },
    };
  },
  watch: {
    "data.fromDate"(value) {
      if (value) {
        this.getResult();
      }
    },
  },
  methods: {
    removeMatch(type) {
      if (type == 1) {
        if (this.result.bcone.length <= 0) {
          return;
        }

        this.result.bcone.splice(this.result.bcone.length - 1, 1);
      }
      if (type == 2) {
        if (this.result.bctwo.length <= 0) {
          return;
        }

        this.result.bctwo.splice(this.result.bctwo.length - 1, 1);
      }
    },
    addMatch(type) {
      if (type == 1) {
        let lastNo = 1;
        if (this.result.bcone.length > 0) {
          lastNo = this.result.bcone[this.result.bcone.length - 1].no + 1;
        }
        this.result.bcone.push({
          channelId: 3,
          channelType: 2,
          no: lastNo,
          fourCard: null,
          fiveCard: null,
          sixCard: null,
          countMatches: null,
        });
      }
      if (type == 2) {
        let lastNo = 1;
        if (this.result.bctwo.length > 0) {
          lastNo = this.result.bctwo[this.result.bctwo.length - 1].no + 1;
        }
        this.result.bctwo.push({
          channelId: 4,
          channelType: 2,
          no: lastNo,
          fourCard: null,
          fiveCard: null,
          sixCard: null,
          countMatches: null,
        });
      }
    },
    show() {
      this.data = {
        fromDate: "",
      };
      this.result = {
        date: null,
        bcone: [],
        bctwo: [],
      };
      this.isShow = true;
      this.key++;
    },
    hide() {
      this.isShow = false;
      this.loading = false;
    },
    stopLoading() {
      this.loading = false;
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      BaccaratResultRepository.cloneByDay({
        date: this.result.date,
        lives: [...this.result.bcone, ...this.result.bctwo],
      })
        .then((response) => {
          this.$emit("save", this.data.toDate);
          this.hide();
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.form.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getResult() {
      if (!this.data.fromDate) {
        return;
      }
      this.loading = true;
      BaccaratResultRepository.getResultByDay({
        date: this.data.fromDate,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.result.bcone = [...data.channel3];
            this.result.bctwo = [...data.channel4];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;

    return { fields };
  },
};
</script>